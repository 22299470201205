import React from 'react'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { PrimaryButton, SecondaryButton } from '@truphone/buttons'

export default function SalesBanner(props) {
  const imageStyles = {}
  if (props.image?.customData) {
    imageStyles['mix-blend-mode'] = props.image.customData['mix-blend-mode']
  }
  return (
    <div
      className={`${
        !props.backgroundColor && !props.textColor ? 'bg-blue-300 text-white' : ''
      } relative overflow-hidden`}
      style={{
        background: props.backgroundColor,
        color: props.textColor,
        minHeight: `${props.minHeight}px`
      }}
    >
      {props.style === 'full-background' && props.backgroundImage && (
        <div className="hidden lg:block w-full h-full absolute left-0 top-0 bg-cover bg-center overflow-hidden">
          <ImageWrap className="block w-full h-full object-cover" {...props.backgroundImage} />
        </div>
      )}

      <div className={`relative bg-bottom lg:bg-right bg-no-repeat bg-contain w-full`}>
        <div
          className={`flex flex-wrap relative container mx-auto px-6 pt-10 md:pt-20 ${
            props.image ? 'items-start justify-center lg:justify-end' : 'items-center'
          }`}
        >
          {props.style === 'default' && props.backgroundImage && (
            <ImageWrap
              className="block absolute bottom-0 lg:-right-16 xl:right-0 object-cover"
              {...props.backgroundImage}
            />
          )}

          <div className="relative z-1 w-full lg:w-1/2 mb-16 lg:mb-20 lg:pr-20">
            <h3 className="h3-light mb-4">{props.title}</h3>
            <div className="text-base mb-4" dangerouslySetInnerHTML={{ __html: props.content }} />

            {props.cta && (
              <>
                {props.cta.primary ? (
                  <PrimaryButton
                    target={props.cta.openTab ? '_blank' : ''}
                    className="mt-4 font-medium w-full lg:w-auto"
                    href={props.cta.href}
                  >
                    {props.cta.text}
                  </PrimaryButton>
                ) : (
                  <SecondaryButton
                    target={props.cta.openTab ? '_blank' : ''}
                    className="mt-4 font-medium w-full lg:w-auto"
                    href={props.cta.href}
                  >
                    {props.cta.text}
                  </SecondaryButton>
                )}
              </>
            )}
          </div>

          <div
            className={`relative w-full h-full lg:w-1/2 flex flex-col ${
              props.image ? 'justify-center' : 'items-center lg:pl-10'
            }`}
          >
            {props.quote && (
              <p className="lg:mx-auto mb-16 lg:mb-8 text-xl lg:text-center text-green-100 font-medium whitespace-pre-line lg:whitespace-normal">
                {props.quote}
              </p>
            )}
            {props.image && (
              <ImageWrap className="block relative mx-auto" style={imageStyles} {...props.image} />
            )}

            {props.contentRight && (
              <div
                className="text-sm list-tick list-square-tick"
                dangerouslySetInnerHTML={{ __html: props.contentRight }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
